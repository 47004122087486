<template>
  <div>
    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
    </div>
    <s-table
      ref="table"
      size="middle"
      :pageSize="20000"
      :showSizeChanger="false"
      :columns="columns"
      :data="loadData"
      rowKey="id"
    >
      <div
        slot="expandedRowRender"
        slot-scope="record"
        style="margin: 0">
        <a-row
          :gutter="24"
          :style="{ marginBottom: '12px' }">
          <a-col :span="12" v-for="(role, index) in record.permissions" :key="index" :style="{ marginBottom: '12px' }">
            <a-col :span="4">
              <span>{{ role.permissionName }}：</span>
            </a-col>
            <a-col :span="20" v-if="role.actionEntitySet.length > 0">
              <a-tag color="cyan" v-for="(action, k) in role.actionEntitySet" :key="k" v-if="action.defaultCheck">{{ action.description }}</a-tag>
            </a-col>
            <a-col :span="20" v-else>-</a-col>
          </a-col>
        </a-row>
      </div>
      <span slot="action" slot-scope="text, record">
        <a @click="handleEdit(record)" v-action:edit>编辑</a>
        <a-divider type="vertical" />
        <a-popconfirm
          title="确定要删除么？"
          ok-text="确定"
          cancel-text="取消"
          @confirm="handleDelet(record)"
        >
          <a>删除</a>
        </a-popconfirm>
      </span>
    </s-table>
  </div>
</template>

<script>
import { STable } from '@/components'
import RoleModal from '../modules/RoleModal'
import { permission_group_list, permission_group_delete } from '@/api/permission_group'
import moment from 'moment'
import { Dictionaries } from '@/common/AllConstant'

export default {
  name: 'TableList',
  components: {
    STable,
    RoleModal
  },
  data () {
    return {
      description: '',
      EditLoading: false,
      eidtVisible: false,

      form: null,
      mdl: {},

      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '权限组名称',
          dataIndex: 'name',
          ellipsis: true
        },
        {
          title: '备注',
          dataIndex: 'description'
        },
        {
          title: '创建时间',
          dataIndex: 'create_time',
          customRender: (text) => text || '-',
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('loadData.parameter', parameter)
        this.queryParam.corporation_ids = Dictionaries.corporation_id
        return permission_group_list(Object.assign(parameter, this.queryParam, { site: 2 })).then((res) => {
            var result = {
              entries: res.data || []
            }
            result.entries.forEach((item) => {
              // eslint-disable-next-line no-eval
              item.permissions = eval(item.view_json)
            })
            return result
        })
      },

      selectedRowKeys: [],
      selectedRows: []
    }
  },
  methods: {
    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    },
    handleDelet (record) {
      permission_group_delete(record.id).then((res) => {
        console.log(res)
        console.log('删除成功------')

        this.$refs.table.refresh()
      })
    },
    handleEdit (record) {
      // this.$emit('onEdit', record)
      this.$router.push({ path: '/role/RoleInformation/' + record.id })
    },
    handleAdd () {
      this.$emit('onCreate', {})
    },
    handleEditCancel () {
      this.eidtVisible = false
      const form = this.$refs.editRole.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleOk () {
      // 新增/修改 成功时，重载列表
      this.$refs.table.refresh()
    },
    onChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    }
  },
  watch: {
    /*
      'selectedRows': function (selectedRows) {
        this.needTotalList = this.needTotalList.map(item => {
          return {
            ...item,
            total: selectedRows.reduce( (sum, val) => {
              return sum + val[item.dataIndex]
            }, 0)
          }
        })
      }
      */
  }
}
</script>
